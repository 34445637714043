import React, { useState, useEffect } from "react"
import Swipe from "react-swipeable-views"
import Image from "gatsby-image"
import styled from "styled-components"

import LeftArrow from "src/svg/left.svg"
import RightArrow from "src/svg/right.svg"

const Container = styled.section`
  position: relative;
`
const Button = styled.button`
  position: absolute;
  top: calc(50% - 15px);
  svg {
    height: 30px;
  }
`
const LeftButton = styled(Button)`
  left: -24px;
`
const RightButton = styled(Button)`
  right: -25px;
`

interface Props {
  photos: Media[]
  index: number
  onChange: (index: number) => void
}

const Slideshow = ({ photos, index, onChange }: Props) => {
  const [clicked, setClicked] = useState(false)

  const handleManual = (idx: number) => {
    setClicked(true)
    onChange(idx)
  }

  const handleLeft = () => {
    handleManual(Math.max(0, index - 1))
  }

  const handleRight = () => {
    handleManual(Math.min(photos.length - 1, index + 1))
  }

  useEffect(() => {
    if (clicked) {
      return
    }

    const next = () => {
      onChange(index < photos.length - 1 ? index + 1 : 0)
    }

    const timeout = setTimeout(next, 5000)

    return () => {
      clearTimeout(timeout)
    }
  }, [photos.length, clicked, index, onChange])

  return (
    <Container>
      <Swipe index={index} onChangeIndex={handleManual} resistance>
        {photos.map((photo, i) => (
          <Image key={i} fluid={photo.fluid} loading={i <= index + 1 ? "eager" : "lazy"} />
        ))}
      </Swipe>
      {photos.length > 1 && (
        <>
          <LeftButton onClick={handleLeft}>
            <LeftArrow />
          </LeftButton>
          <RightButton onClick={handleRight}>
            <RightArrow />
          </RightButton>
        </>
      )}
    </Container>
  )
}

export default Slideshow
