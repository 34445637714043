import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "src/layout"
import { useLang } from "src/layout/root"
import { Top, Label, Section, Row, Column } from "src/components/styled"
import RichText from "src/components/RichText"
import Slideshow from "src/components/Slideshow"
import { SIZES, COLORS } from "src/layout/constants"

const ImageColumn = styled.div`
  flex: 2;
  min-width: 200px;
  @media (min-width: 500px) {
    min-width: 400px;
  }
  margin: ${SIZES.margin}px;
  @media (max-width: 799px) {
    margin: 0 ${SIZES.mobileMargin}px;
  }
`
const ContentColumn = styled(Column)`
  display: flex;
  flex-direction: column;
`
const Space = styled.div`
  flex: 1;
`
const Caption = styled.figcaption`
  h4 {
    margin: 1em 0 0.5em;
    color: ${COLORS.grey};
  }
  p {
    margin: 0;
    line-height: 1.3em;
    font-size: 0.85em;
    max-width: 300px;
  }
`

interface DataType {
  projects: { nodes: Project[] }
}
interface ContextType {
  slug: string
}

const ProjectPage: GatsbyPage<DataType, ContextType> = ({ data }) => {
  const { getNodes } = useLang()
  const [project] = getNodes<Project>(data.projects)
  const [index, setIndex] = useState(0)
  const photo = project.carousel[index]

  return (
    <Layout pageTitle={project.name}>
      <Top id="material">
        <h1>{project.name}</h1>
        {/* <Label>TYPOLOGIE / REF MATIÈRE / REF COULEUR</Label> */}
      </Top>
      <Section>
        <Row>
          <ImageColumn>
            <Slideshow photos={project.carousel} index={index} onChange={setIndex} />
          </ImageColumn>
          <ContentColumn>
            <Label>{project.subtitle}</Label>
            <h1>{project.title}</h1>
            {project.description && <RichText node={project.description} />}
            <Space />
            <Caption>
              <h4>{photo.title}</h4>
              <p>{photo.description}</p>
            </Caption>
          </ContentColumn>
        </Row>
      </Section>
    </Layout>
  )
}

export default ProjectPage

export const query = graphql`
  query($slug: String!) {
    projects: allContentfulProjet(filter: { slug: { eq: $slug } }) {
      nodes {
        node_locale
        name
        title
        subtitle
        # mati_re {
        #   reference
        # }
        # motif {
        #   reference
        # }
        description {
          json
        }
        # photo {
        #   fluid(maxWidth: 1600, quality: 75) {
        #     ...GatsbyContentfulFluid
        #   }
        # }
        carousel {
          fluid(maxWidth: 1600, quality: 75) {
            ...GatsbyContentfulFluid
          }
          title
          description
        }
      }
    }
  }
`
